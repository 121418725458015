*:focus {
	outline: 0;
}

html,
body {
	min-height: 100%;
}

body {
	font: 400 14px 'Open Sans', sans-serif;
	background-image: linear-gradient(233deg, #2d3140, #1b2128);
	color: #fff;
	-webkit-font-smoothing: antialiased;
}

.panel {
	background: transparent;
	border: 0;
	box-shadow: none;
}

.panel-heading {
	background: transparent;
	border: 0;
	box-shadow: none;
	padding: 0 0 20px;
}

.panel-heading {
	background: transparent !important;
	border: 0;
}

.panel-body {
	padding: 0;
}

.blob {
	position: fixed;
}

.blob-left {
	top: 60vh;
	left: -110px;
}

.blob-right {
	top: 30vh;
	height: 377px;
	right: -16px;
	width: 187px;
}

.accordion {
	background-color: #fff;
	border-radius: 4px;
	color: #333;
	margin-bottom: 27px;
}

/* .accordion__heading {
	height: 82px;
} */

.accordion__item {
	border-bottom: 1px solid rgba(40, 45, 58, 0.1);
	position: relative;
	transition: all 0.5s ease-in-out;
}

.accordion__item:first-of-type,
.accordion__item:first-of-type:before {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.accordion__item:last-of-type,
.accordion__item:last-of-type:before {
	border: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.accordion__item:last-of-type .tab-content {
	/* border-radius: 0; */
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.accordion__item:last-of-type .tab-content > div > div > div:last-child {
	border-bottom-right-radius: 4px;
}

.accordion__item:hover {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* .accordion__item:hover .accordion__button,
.accordion__item:hover .accordion__panel {
  background-color: #fafafa;
} */

.accordion__item:hover>a,
.accordion__item:hover .accordion__button>div>a {
	opacity: 1;
}

/* .accordion__button[aria-expanded='true'],
.accordion__panel {
	background: #fafafa;
} */

.accordion__item:first-of-type .accordion__button[aria-expanded='true'] {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.accordion__item:last-of-type .accordion__panel {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.accordion__button[aria-expanded='true'] p {
	white-space: initial;
}

.accordion__button h4 {
	font-size: 16px;
	font-weight: 700;
}

.accordion__button>div {
	display: flex;
	padding: 16px 16px 16px 40px;
}

.accordion__button>.accordion__button--inner {
	display: block;
	padding: 0;
}

.accordion__button--inner>div {
	display: flex;
	padding: 16px 16px 4px 40px;
}


.accordion__panel {
	/* animation: fadein 0.5s ease-in; */
	/* padding: 10px 10px 30px 22px; */
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.accordion__panel {
	border-top: 1px solid rgba(40, 45, 58, 0.1);
}

.accordion__panel table,
.weekly-hours-table table {
	margin-top: -19px;
	width: 100%;
}

.weekly-hours-table table {
	margin-top: -16px;
}

.accordion__panel--profile table td:first-child {
	font-weight: 700;
}

.accordion__panel table th,
.weekly-hours-table table th {
	color: rgba(51, 51, 51, 0.5);
	font-size: 12px;
	padding: 12px 28px;
	text-align: center;
	vertical-align: bottom;
}

.accordion__panel>table>thead>tr>th:first-child {
	width: 340px;
}

.weekly-hours-table>table>thead>tr>th:first-child {
	padding-left: 24px;
}

.accordion__panel table th.active,
.accordion__panel table td.active,
.weekly-hours-table table th.active,
.weekly-hours-table table td.active {
	background-color: #f2f2f2;
	/* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1); */
	font-weight: 700;
}

.accordion__panel table th.active,
.weekly-hours-table table th.active {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 0;
}

.accordion__panel table th.active tr:last-child th,
.weekly-hours-table table th.active tr:last-child th {
	color: rgba(51, 51, 51, 0.7);
}

.accordion__panel table th.selected-week-table-head,
.weekly-hours-table table th.selected-week-table-head {
	background-image: linear-gradient(244deg, #ff6200, #db2b2b);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: #fff;
	display: block;
	font-size: 8px;
	padding: 8px 14px;
	text-transform: uppercase;
}

.accordion__panel table th.selected-week-table-head-selected-week,
.weekly-hours-table table th.selected-week-table-head-selected-week {
	background: #333333;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: #fff;
	display: block;
	font-size: 8px;
	padding: 8px 14px;
	text-transform: uppercase;
}

.accordion__panel table tr:last-child td.active,
.weekly-hours-table table tr:last-child td.active {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.accordion__panel table td,
.weekly-hours-table table td {
	font-size: 16px;
	padding: 12px 30px;
	text-align: center;
}

.weekly-hours-table table td {
	padding: 12px 24px;
}

.accordion__panel table td:first-child,
.weekly-hours-table table td:first-child {
	text-align: left;
}

.accordion__panel table td:first-child a,
.weekly-hours-table table td:first-child a {
	color: #000;
	font-weight: 700;
}

.weekly-hours-table table td:first-child a {
	color: #333;
	font-size: 14px;
	white-space: nowrap;
}

.accordion__panel--profile {
	padding: 20px 0;
}

.accordion__panel--profile .nav-tabs {
	padding: 0 20px;
}

.accordion__panel--profile table th img {
	border-radius: 50%;
	height: 42px;
	max-width: initial !important;
	object-fit: cover;
	object-position: top;
	width: 42px;
}

.accordion__panel--profile #controlled-tab-example-pane-1,
.accordion__panel--profile #controlled-tab-example-pane-2 {
	overflow-x: scroll;
	padding-bottom: 10px;
}

.week-label {
	color: rgba(51, 51, 51, 0.5);
	font-weight: 700;
	text-align: left !important;
	text-transform: uppercase;
	white-space: nowrap;
}

.modal-backdrop {
	background-image: linear-gradient(233deg, #2d3140, #1b2128);
}

.modal-backdrop.in {
	opacity: 0.9;
}

.modal-header {
	border-bottom: 0;
	padding: 0 0 40px;
}

.modal-header .close {
	font-size: 40px;
	font-weight: 300;
	opacity: 0.8;
}

.modal-header .close:hover {
	opacity: 1;
}

.modal-body {
	color: #333;
	padding: 0;
}

.modal-body p {
	font-size: 18px;
	margin-bottom: 46px;
}

.select-wrapper {
	background: none;
	border: 0;
	/* border-bottom: 1px solid #333; */
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 0;
	box-shadow: none;
	font-size: 16px;
	height: 37px;
	line-height: 26px;
	margin-bottom: 46px;
	padding: 0;
	width: 100%;
}

.select-wrapper>div {
	background: none;
	border: 0;
	border-radius: 0;
	font-size: 16px;
	padding: 0;
}

.select-wrapper .css-1hwfws3 {
	padding-left: 0 !important;
}

.select-wrapper:focus,
.select-wrapper>div:focus {
	border-color: #333;
	box-shadow: none;
	outline: 0;
}

.css-1pahdxg-control {
	border-color: transparent !important;
	box-shadow: none !important;
}

.css-26l3qy-menu {
	background: #fff !important;
	color: #333;
	margin-top: 2px !important;
}

.css-1ml51p6-MenuList>div {
	font-size: 16px;
}

.btn {
	border: 0;
	border-radius: 0;
	outline: none;
	transition: all 0.5s ease-in-out;
}

.btn:focus {
	outline: 0;
}

.btn-primary {
	background-image: linear-gradient(262deg, #ff6200, #db2b2b);
}

.btn-primary:hover {
	background-image: linear-gradient(262deg, #db2b2b, #ff6200);
}

.btn-white {
	background-color: #fff;
	color: #333;
}

.btn-white:hover {
	background-color: #e2e2e2;
}

.btn-default {
	background-image: linear-gradient(254deg, #2d3140, #1b2128);
	color: #fff;
}

.btn-default:hover {
	background-image: linear-gradient(254deg, #1b2128, #2d3140);
	color: #fff;
}

.modal-footer {
	border: 0;
	padding: 0;
}

.btn-wrap {
	display: flex;
}

.btn-wrap .btn {
	font-size: 16px;
	height: 62px;
	margin: 0 !important;
	text-transform: uppercase;
	width: 328px;
}

.btn-wrap button:only-of-type {
	border-radius: 4px;
}

.btn-wrap .btn-primary {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.btn-wrap .btn-default {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.btn-login {
	padding: 4px 10px 4px 16px !important;
}

.btn-login>div {
	height: 18px;
	padding: 0 !important;
}

.btn-login span {
	font: 400 15px 'Open Sans', sans-serif;
	padding-top: 8px !important;
}

.scrollarea {
	height: 260px;
	width: 260px;
}

.scrollarea .scrollbar-container,
.scrollarea .scrollbar-container .scrollbar {
	border-radius: 3px !important;
	opacity: 1 !important;
	width: 4px !important;
}

.scrollarea .scrollbar-container {
	background-color: rgba(151, 151, 151, 0.5) !important;
	height: calc(100% - 10px) !important;
	top: 10px !important;
	overflow: hidden;
}

.scrollarea .scrollbar-container .scrollbar {
	background-color: #ff6200 !important;
	margin-left: 0 !important;
}

@media all and (min-width: 768px) {
	.modal-dialog {
		margin: 80px auto;
		width: 644px;
	}

	.modal-header h4 {
		color: #333;
		font-size: 24px;
	}

	.modal-content {
		padding: 36px 52px 78px;
	}
}

.redux-toastr .bottom-left {
	bottom: 18vh;
	left: 37px;
}

.redux-toastr .toastr {
	background-color: #fff !important;
	border-radius: 4px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	min-height: 55px;
	min-width: 232px;
	max-width: 252px;
}

.redux-toastr .toastr .rrt-text {
	color: #333;
	font: 12px 'Open Sans', sans-serif;
}

.redux-toastr .toastr .toastr-icon {
	height: 24px;
	width: 24px;
}

.redux-toastr .toastr .rrt-left-container {
	width: 50px;
}

.redux-toastr .toastr .rrt-left-container svg {
	display: none;
}

.redux-toastr .toastr .rrt-left-container:before {
	content: '';
	height: 24px;
	left: 14px;
	position: absolute;
	top: 15px;
	width: 24px;
}

.redux-toastr .toastr.rrt-success .rrt-left-container:before {
	background: url(../src/images/ic-success.svg) no-repeat 0 0;
}

.redux-toastr .toastr.rrt-error .rrt-left-container:before {
	background: url(../src/images/ic-error.svg) no-repeat 0 0;
}

.redux-toastr .toastr.rrt-warning .rrt-left-container:before {
	background: url(../src/images/ic-warning.svg) no-repeat 0 0;
}

.redux-toastr .toastr.rrt-info .rrt-left-container:before {
	background: url(../src/images/ic-info.svg) no-repeat 0 0;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
	width: 40px;
}

.redux-toastr .toastr .rrt-middle-container {
	margin-left: 42px;
	padding: 18px 5px;
}

.css-tj5bde-Svg {
	/* fill: #333 !important; */
	fill: #fff !important;
}

.css-1okebmr-indicatorSeparator {
	background-color: #333 !important;
}

.form-group>input[type='text'],
.form-group>input[type='search'] {
	display: block;
	width: 342px;
	background: transparent;
	border: none;
	border-bottom: 1px solid rgba(216, 216, 216, 0.3);
	border-radius: 0;
	line-height: 20px;
	padding-bottom: 10px;
	margin: 6px 0 0;
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 36px;
	top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #bdc3c7;
}

.bm-menu-wrap {
	position: fixed;
	height: 100%;
	top: 100px;
	width: 534px !important;
}

/* General sidebar styles */
.bm-menu {
	background-image: linear-gradient(206deg, #2d3140, #1b2128);
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
}

.bm-item-list label {
	color: rgba(255, 255, 255, 0.5);
	font-size: 10px;
	font-weight: 400;
	margin: 0;
	text-transform: uppercase;
}

/* Individual item */
.bm-item {
	color: #fff;
	display: inline-block;
	margin-bottom: 23px;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}

.flex {
	display: flex;
	align-items: baseline;
}

.select-hour+span {
	color: rgba(255, 255, 255, 0.25);
	font-size: 15px;
	font-weight: 700;
}

.select-remarks {
	font-size: 14px;
}

input:-webkit-autofill {
	transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.protip-span {
	display: none;
}

@media only screen and (min-width: 1024px) {
	.protip-span {
		display: block;
	}
}

.bm-burger-button {
	display: none;
}

.no-deliverable {
	font-style: italic;
	margin-left: -4px;
}

.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
	background-color: #2d3140;
	color: white;
}

.dropdown-menu{
	background-color: #2d3140;
	color: white;
	cursor: default;
}

.dropdown-menu div{
	cursor: default;
}

.open>.dropdown-menu {
	width: 260px;
}

.header-leave-custom-dropdown{
	cursor: default;
}

.header-leave-custom-dropdown:hover {
	background: none;
}

.header-leave-dropdown{
	background-color: #2c3040;
	border-radius: 8px;
	border: 2px solid #353946;
	padding: 4px;
}

.header-leave-dropdown:hover{
	background: none;
}

.no-hover-effect{
	background-color: transparent !important;
	border: 2px solid #353946 !important;
}


.leave-header{
	margin-left: 16px;
}

.today-leave-container {
	max-height: 265px;
	overflow-y:auto !important;

	/*scrollbar-width: thin;*/
	/*scrollbar-color: #888 #2e2e2e;*/
}

/* width */
.today-leave-container::-webkit-scrollbar {
	width: 3px;

}

/* Track */
.today-leave-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #262833;
	border-radius: 10px;
}

/* Handle */
.today-leave-container::-webkit-scrollbar-thumb {
	background: #667488;
	border-radius: 10px;
}





/*.today-leave-container::-webkit-scrollbar {*/
/*	width: 8px;*/
/*	outline:1px solid red !important;*/
/*}*/

/*.today-leave-container::-webkit-scrollbar-track {*/
/*	background: #2e2e2e;*/
/*}*/

/*.today-leave-container::-webkit-scrollbar-thumb {*/
/*	background-color: #888;*/
/*	border-radius: 20px;*/
/*	border: 2px solid #2e2e2e;*/
/*}*/

/*.today-leave-container::-webkit-scrollbar-button {*/
/*	display: none;*/
/*}*/

/*.today-leave-container::-webkit-scrollbar-thumb:hover {*/
/*	background-color: #bbb;*/
/*}*/

